import { Android } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { QuestionSourceCreate } from "./question-source-create";
import { QuestionSourceEdit } from "./question-source-edit";
import { QuestionSourceList } from "./question-source-list";

export const questionSourceResource = {
    name: ApplicationResource.QUESTION_SOURCE,
    icon: Android,
    options: { label: "Fonte" },
    link: "/question-sources",
    tableName: 'question-source',
    list: QuestionSourceList,
    edit: QuestionSourceEdit,
    create: QuestionSourceCreate,
    products: ['quiz']
}