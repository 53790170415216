import React, { useContext, useState } from "react";
import { BooleanInput, Button, CheckboxGroupInput, Edit, FormTab, TabbedForm, TextInput, useRefresh } from "react-admin";
import MaskedInput from "../../components/masked-input";
import TextFieldWithClipboardButton from "../../components/text-field-with-clipboard-button";
import { AppContext } from "../../providers/app-context-provider/app.context";
import { storageProvider } from '../../providers/storage-provider';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { Spacer } from "../../components/spacer";
import dataProvider from "../../providers/data-provider";

const InstitutionEdit = (props) => {

  const CustomTitle = ({ record }) => {
    return <span> {record.name} </span>
  }

  const [openApiKeyDialog, setOpenApiKeyDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()
  const superAdmin = storageProvider.getCurrentUser()?.superAdmin
  const context = useContext(AppContext)

  const product = context.product;
  const handleDialogClose = () => { setOpenApiKeyDialog(false) }
  const handleDialogOpen = () => { setOpenApiKeyDialog(true) }
  const generateApiKeyV1 = () => {
    setLoading(true)
    dataProvider.get('institutions', `${props.id}/generateApiKeyV1`).then(
      () => {
        refresh()
        handleDialogClose()
        setLoading(false)
      }
    )
  }

  return (
    <Edit
      mutationMode="pessimistic"
      title={<CustomTitle />}
      {...props}
    >
      <TabbedForm>
        <FormTab label={'Dados Gerais'}>
          <TextInput source="name" label={'Nome'} fullWidth disabled={!superAdmin} />
          <TextInput source="slug" label={'slug'} fullWidth disabled={!superAdmin} />
          <MaskedInput source="cnpj" onlyNumbers={true} mask={'99.999.999/9999-99'} label={'CNPJ'} fullWidth disabled={!superAdmin} />
          {superAdmin && <BooleanInput source="active" label={'Ativa'} fullWidth />}
          {product.id === 1 && (superAdmin ?
            <TextInput source="video_room" label={'Link da sala de audiência no metaverso'} fullWidth />
            : <TextFieldWithClipboardButton label={'Link da sala de audiência no metaverso'} source="video_room" fullWidth />)}
          {product.id === 2 && <TextFieldWithClipboardButton label={'Link para comunicação externa com o correio do atendimento'} url={'https://app.juristta.com/#/external-process-mail'} sourceless={true} />}
        </FormTab>

        {
          superAdmin && <FormTab label={'Módulos'} path="products">
            <CheckboxGroupInput
              source="products"
              label="Módulos que a instituição pode acessar"
              variant="filled"
              choices={[{ id: 1, name: 'Juristta School' }, { id: 2, name: 'Juristta Office' }, { id: 4, name: 'Juristta Quiz' }]}
              optionText="name"
              optionValue="id"
              fullWidth
              parse={value =>
                value && value.map(v => ({ id: v }))
              }
              format={value => value && value.map(v => v.id)}
            />
          </FormTab>
        }
        {
          superAdmin && <FormTab label={'Chave API'} path="api-keys">
            <TextFieldWithClipboardButton label='Chave API' source="api_key" urlField="api_key" />
            <Dialog fullWidth open={openApiKeyDialog} onClose={handleDialogClose}>
              <DialogTitle>Operação perigosa</DialogTitle>
              <DialogContent>
                <DialogContentText> Atenção! Gerar uma nova chave API vai remover a chave atual.
                  Isto vai causar disrupção dos serviços dos clientes que dependam desta chave para autenticação.</DialogContentText>
                <DialogContentText> Somente gere uma nova chave com permissão expressa da instituição e com o entendimento que serviços
                  configurados pelo setor de TI vão parar de funcionar até que a nova chave seja incorporada.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button disabled={loading} label='Cancelar' onClick={handleDialogClose} />
                <Spacer />
                <Button disabled={loading} label='Continuar' onClick={generateApiKeyV1} />
              </DialogActions>
            </Dialog>
            <Button
              style={{ width: '20%' }}
              label='Gerar nova chave API para esta instituição'
              onClick={handleDialogOpen}
            />
          </FormTab>
        }

        <FormTab label={'Notificações e email'}>
          <BooleanInput source="sendMailToManagerOnAdd" label={'Enviar email automaticamente ao adicionar um gestor a um atendimento'} fullWidth />
          <BooleanInput source="sendMailToManagerOnRemove" label={'Enviar email automaticamente ao remover um gestor a um atendimento'} fullWidth />
          <BooleanInput source="sendMailToStudentOnAdd" label={'Enviar email automaticamente ao adicionar um aluno a uma movimentação'} fullWidth />
          <BooleanInput source="sendMailToStudentOnRemove" label={'Enviar email automaticamente ao remover um aluno a uma movimentação'} fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
export default InstitutionEdit;
