import { Button, Container, MenuItem, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { Title, useNotify, useRedirect } from "react-admin";
import dataProvider from "../../providers/data-provider";

export const QuizPage = (props) => {

    const [selectedThemes, setSelectedThemes] = useState([]);
    const [themes, setThemes] = useState([]);
    const [sourceType, setSourceType] = useState(undefined);
    const [sourceTypes, setSourceTypes] = useState([]);
    const [question_amount, setQuestionCount] = useState(10);
    const [time_to_solve, setTimeToSolve] = useState(30);
    const [loading, setLoading] = useState(false)
    const redirect = useRedirect()
    const notify = useNotify()

    useEffect(() => {
        const fetchThemes = async () =>
            await dataProvider.get('themes', '', { filter: JSON.stringify({ has_questions: true }), sort: '"theme.name"', order: '"ASC"' })
                .then((res) => setThemes(res.data.data))
        fetchThemes()
    }, [])

    useEffect(() => {
        const fetchQuestionSourceTypes = async () =>
            await dataProvider.get('question-source-types', '', { sort: '"question-source-type.name"', order: '"ASC"' })
                .then((res) => setSourceTypes(res.data.data))
        fetchQuestionSourceTypes()
    }, [])

    const handleGenerateQuiz = async () => {
        setLoading(true)
        await dataProvider.post('quiz', '', { themes: selectedThemes, question_amount, time_to_solve })
            .then((response) => {
                notify('Quiz gerado com sucesso!', 'success')
                redirect(`/solve-quiz/${response.data.id}`)
            })
            .catch(() => notify('Erro ao gerar o quiz', 'error'))
            .finally(() => setLoading(false))
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
            <Title title={"Novo Quiz"} />
            <Typography variant="h4" gutterBottom>
                {"Gerar novo quiz pessoal"}
            </Typography>

            <Autocomplete
                options={themes}
                getOptionLabel={(option) => option?.name}
                value={selectedThemes}
                onChange={(event, newValue) => setSelectedThemes(newValue)}
                disabled={themes.length === 0}
                multiple
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label="Áreas do direito"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                )}
            />

            <Autocomplete
                options={sourceTypes}
                getOptionLabel={(option) => option?.name}
                value={sourceType}
                onChange={(event, newValue) => setSourceType(newValue)}
                disabled={sourceTypes.length === 0}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label="Tipos de questão"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                )}
            />

            <TextField
                select
                label="Número de Questões"
                value={question_amount}
                onChange={(e) => setQuestionCount(Number(e.target.value))}
                fullWidth
                margin="normal"
                variant="outlined"
            >
                {[5, 10, 15, 20].map((count) => (
                    <MenuItem key={count} value={count}>
                        {count}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                label="Tempo para resolução"
                value={time_to_solve}
                onChange={(e) => setTimeToSolve(Number(e.target.value))}
                fullWidth
                margin="normal"
                variant="outlined"
            >
                {[30, 60, 90].map((count) => (
                    <MenuItem key={count} value={count}>
                        {`${count} minutos`}
                    </MenuItem>
                ))}
            </TextField>


            <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "2rem" }}
                onClick={handleGenerateQuiz}
                disabled={loading || selectedThemes.length < 1 || !sourceType}
            >
                {"Gerar Quiz"}
            </Button>
        </Container>
    );
}