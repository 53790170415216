import { List, Box, Button, Container, ListItem, ListItemText, Typography } from "@material-ui/core";
import { useState } from "react";
import dataProvider from "../../providers/data-provider";
import QuizResolutionPage from "./quiz-resolution-page.component";

export const QuizSolve = (props) => {
    const id = props.match.params.id;

    const [quizData, setQuizData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [started, setStarted] = useState(false);


    const handleStartQuiz = async () => {
        setLoading(true);
        try {
            const res = await dataProvider.get("quiz", id);
            setQuizData(res.data);
            setStarted(true);
        } catch (error) {
            console.error("Erro ao iniciar o quiz:", error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Container maxWidth="md" style={{ marginTop: "2rem" }}>
                <Typography variant="h4" gutterBottom>
                    Carregando...
                </Typography>
            </Container>
        );
    }

    if (started && quizData) {
        return <QuizResolutionPage quiz={quizData} />;
    }

    return (
        <Container maxWidth="md" style={{ marginTop: "2rem" }}>

            <Typography variant="h4" gutterBottom>
                Instruções
            </Typography>
            <Typography variant="h6" align="justify" gutterBottom>
                Bem-vindo(a) ao quiz! Antes de começar, leia atentamente as instruções abaixo para garantir uma boa experiência:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText>
                        <Typography variant="body1" align="justify">
                            <strong>Como responder às questões:</strong> O quiz é composto de perguntas de múltipla escolha. Para selecionar uma resposta, clique no <strong>círculo</strong> ao lado direito da alternativa desejada.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <Typography variant="body1" align="justify">
                            <strong>Navegação entre questões:</strong> Você pode navegar pelas perguntas utilizando os botões <strong>"Anterior"</strong> e <strong>"Próximo"</strong>. Alternativamente, clique diretamente na questão desejada na <strong>linha do tempo</strong> localizada no topo da interface.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <Typography variant="body1" align="justify">
                            <strong>Tempo limite:</strong> Caso o quiz tenha um limite de tempo, um <strong style={{ color: '#ab0202' }}>timer vermelho</strong> será exibido no canto superior direito da tela. Fique atento ao tempo disponível para completar o quiz.
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText>
                        <Typography variant="body1" align="justify" gutterBottom>
                            <strong>Finalização do quiz:</strong> Todas as perguntas devem ser respondidas para que o botão <strong>"Finalizar"</strong> seja habilitado. Este botão está localizado no canto inferior direito da tela. Após finalizar, suas respostas serão enviadas para análise.
                        </Typography>
                    </ListItemText>
                </ListItem>
            </List>

            <Typography variant="h6">
                Boa sorte! Quando estiver pronto(a), clique no botão "Iniciar Quiz" para começar.
            </Typography>


            <Box textAlign="center" marginTop="2rem">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleStartQuiz}
                >
                    Iniciar Quiz
                </Button>
            </Box>

        </Container>
    );
};
