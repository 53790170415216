import { Box, Divider, List, ListItem, ListItemText, Menu, MenuItem, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { ExitToApp, Person } from "@material-ui/icons";
import { useContext, useState } from "react";
import { useLogout, useRedirect } from "react-admin";
import { AppContext } from "../../providers/app-context-provider/app.context";
import { calcStarterTheme } from "../../providers/app-context-provider/calc-theme";
import cookiesProvider from "../../providers/cookies-provider";
import { storageProvider } from "../../providers/storage-provider";
import { Spacer } from "../spacer";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #ededed',
    marginRight: '8px'
  }
})((props) => (
  <Menu
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
    transformOrigin={{ vertical: 'top', horizontal: 'right', }}
    {...props}
  />
))

const ProductMenu = ({ appContext, selectProduct, fullClose, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const products = appContext.products.filter(el => el.id !== appContext.product.id)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Alterar Módulo
      </Button>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {products.map((product) => <MenuItem key={`menu-item-${product.id}`} onClick={() => { handleClose(); selectProduct(product.id); }}>{product.name}</MenuItem>)}
      </StyledMenu>
    </>
  )
}

export const ProfileInfoComponent = ({ ...props }) => {
  const makeLogout = useLogout();
  const redirect = useRedirect();
  const appContext = useContext(AppContext);

  const selectProduct = (id) => {
    const preferredTheme = cookiesProvider.getPreferredTheme()
    const newProduct = appContext.products.find(product => product.id === id)
    appContext.setProduct(newProduct)
    appContext.setTheme(calcStarterTheme(newProduct, preferredTheme))
    const currentUser = storageProvider.getCurrentUser()
    currentUser.product = newProduct
    storageProvider.setCurrentUser(currentUser)
    redirect('/')
  }

  return (
    <Box style={{ width: '450px' }}>
      <List dense disablePadding>
        <ListItem>
          <ListItemText primary={appContext.product.name} secondary={'Módulo atual'} />
          <Spacer />
          <ProductMenu appContext={appContext} selectProduct={selectProduct} />
          {/* <Button
            disabled={appContext.products.length === 1}
            onClick={() => { toggleProduct() }}>
            Alterar módulo
          </Button>
         */}
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={appContext.currentUser.name}
            secondary={appContext.superAdmin ? 'Superadministrador' : appContext.currentUser.profiles[0].name}
          />
          <Spacer />
          <Button startIcon={<Person />} onClick={() => redirect('/me')}>
            Meus dados
          </Button>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={appContext.institution.name}
            secondary={'Instituição selecionada'}
          />
          {/*    <Spacer />
          <Button disabled={appContext.userInstitutions.length === 1}>
            Alterar Instituição
          </Button> */}
        </ListItem>
        <Divider />
        <ListItem>
          <Spacer />
          <Button startIcon={<ExitToApp />} onClick={makeLogout}>
            Sair
          </Button>
        </ListItem>
      </List>
    </Box>
  )
}