import { Avatar, Badge, Button, Divider, IconButton, InputBase, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Typography } from '@material-ui/core';
import { /*Brightness4, Brightness7,*/ ExpandMore, KeyboardArrowRight, Notifications } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import { AppBar, useRedirect } from 'react-admin';
import { AppContext } from '../providers/app-context-provider/app.context';
import dataProvider from "../providers/data-provider";
import { storageProvider } from '../providers/storage-provider';
//import { officeDarkTheme, officeLightTheme, schoolDarkTheme, schoolLightTheme } from '../themes';
import { ProfileInfoComponent } from '../components/profile-info';
import { useStyles } from './styles';
export const CustomAppBar = (props) => {

    const classes = useStyles();
    const redirect = useRedirect();
    const appContext = useContext(AppContext)

    const [value, setValue] = useState('')
    const [filter, setFilter] = useState('name')
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([])

    const userProfile = storageProvider.getCurrentUser()?.profiles

    const student = userProfile?.find((profile) => profile.name === 'Aluno')

    useEffect(() => {
        const getNotifications = async () => {
            const res = await dataProvider.getNotifications({ sort: 'created_at', order: 'DESC' })
            setNotifications(res.data.data)
        }
        getNotifications();
    }, [popoverAnchorEl])

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const goToNotificationPage = () => {
        setPopoverAnchorEl(null);
        redirect(`/notifications/`)
    }

    const handlePopoverClick = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    }

    const handleMenuClose = (value) => {
        setFilter(value)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const translateFilter = (value) => {
        switch (value) {
            case 'name':
                return 'Nome';
            case 'cpf':
                return 'CPF/CNPJ'
            case 'phone':
                return 'Telefone'
            case 'service':
                return 'Protocolo'
            case 'process':
                return 'Processo'
            case 'email':
                return 'Email';
            default:
                break;
        }
    }

    const handleSubmit = () => { redirect(`/search?filter=${filter}&value=${value}`) }

    const unread = notifications?.filter(n => n.read === false).length

    /* const toggleTheme = () => {
         const product = appContext.product
         const currentTheme = appContext.theme.palette.type
         if (currentTheme === 'dark') {
             if (product?.id === 1) {
                 appContext.setTheme(schoolLightTheme)
             } else {
                 appContext.setTheme(officeLightTheme)
             }
             cookiesProvider.setPreferredTheme('light')
         } else {
             if (product?.id === 1) {
                 appContext.setTheme(schoolDarkTheme)
             } else {
                 appContext.setTheme(officeDarkTheme)
             }
             cookiesProvider.setPreferredTheme('dark')
         }
     }*/

    return (
        <AppBar {...props} position='fixed' className={classes.appBar} logout={<ProfileInfoComponent />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />

            {
                appContext.product.id === 2 && !student && <>
                    <Button aria-controls="simple-menu" color='primary' aria-haspopup="true" onClick={handleMenuClick} className={classes.searchButton} variant='contained'>
                        {translateFilter(filter)}
                        <ExpandMore />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem key='search-menu-item-1' onClick={() => handleMenuClose('name')}>Nome </MenuItem>
                        <MenuItem key='search-menu-item-2' onClick={() => handleMenuClose('cpf')}>CPF/CNPJ</MenuItem>
                        <MenuItem key='search-menu-item-3' onClick={() => handleMenuClose('phone')}>Telefone</MenuItem>
                        <MenuItem key='search-menu-item-4' onClick={() => handleMenuClose('service')}>Protocolo</MenuItem>
                        <MenuItem key='search-menu-item-5' onClick={() => handleMenuClose('process')}>Processo</MenuItem>
                        <MenuItem key='search-menu-item-6' onClick={() => handleMenuClose('email')}>Email</MenuItem>
                    </Menu>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            onChange={handleChange}
                            placeholder="Busca de atendimentos"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={value}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <Button style={{ marginLeft: '8px' }} color='inherit' className={classes.searchButton} onClick={handleSubmit} variant='contained' disabled={value.length === 0}> Buscar </Button>
                </>
            }
            <IconButton color='inherit' onClick={handlePopoverClick}>
                <Badge color='error' overlap='rectangular' badgeContent={unread} max={9}>
                    <Notifications />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={popoverAnchorEl}
                open={Boolean(popoverAnchorEl)}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <List style={{ maxWidth: '400px' }} dense>
                    <ListItem key={'notifications'}>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary={`Notificações recentes ${unread > 0 ? `(${unread} não lidas)` : ''}`} />
                    </ListItem>
                    <Divider />
                    {
                        notifications && notifications?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 5).map(
                            (notification, idx) => {
                                return <ListItem key={`notification-${idx + 1}`}>
                                    <ListItemAvatar>
                                        <Badge color='error' variant='dot' overlap='rectangular' invisible={notification.read}>
                                            <Avatar src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristta_simples.png'} variant='rounded' />
                                        </Badge>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={notification.title}
                                        primaryTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                        secondary={moment(notification.created_at).format('LL')}
                                        secondaryTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                    />
                                </ListItem>

                            }
                        )
                    }
                    <Divider />
                    <ListItem key={'all-notifications'}>
                        <Button onClick={goToNotificationPage} startIcon={<KeyboardArrowRight />}>
                            Todas as notificações
                        </Button>
                    </ListItem>
                </List>
            </Popover>
            {/*     <IconButton color='inherit' onClick={toggleTheme}>
                {appContext.theme.palette.type === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>*/}
        </AppBar >
    )

}