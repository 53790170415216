import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import moment from "moment"
import { Spacer } from "../../components/spacer"
import { useRedirect } from "react-admin"

const QuizFinishModal = ({ finishedByAnswers, finishedByTimeout, submitting, evaluateData }) => {

    const redirect = useRedirect()

    return (
        <Dialog open={finishedByAnswers || finishedByTimeout} maxWidth={'md'} fullWidth>
            <DialogTitle>
                {!evaluateData && finishedByAnswers && "Você finalizou o quiz!"}
                {!evaluateData && finishedByTimeout && "Seu tempo acabou!"}
                {evaluateData && "Resultado"}
            </DialogTitle>
            {
                !evaluateData && finishedByTimeout && <DialogContent>
                    <DialogContentText>{"O tempo estipulado para a resolução do quiz foi encerrado."}</DialogContentText>
                </DialogContent>
            }
            {
                !evaluateData && submitting && <DialogContent>
                    <DialogContentText>{"Aguarde enquanto enviamos as questões para correção."}</DialogContentText>
                </DialogContent>
            }
            {evaluateData && <DialogContent>
                <DialogContentText>{`Total de questões: ${evaluateData.questions.length}`}</DialogContentText>
                <DialogContentText>{`Questões respondidas: ${evaluateData.answers.length}`}</DialogContentText>
                <DialogContentText>{`Acertos: ${evaluateData.grade}`}</DialogContentText>
                <DialogContentText>{`Tempo estipulado para resolução: ${evaluateData.time_to_solve} minutos`}</DialogContentText>
                <DialogContentText>{`Tempo utilizado: ${moment.duration(moment(evaluateData.finished_at).diff(moment(evaluateData.started_at))).asMinutes().toFixed(0)} minutos`}</DialogContentText>
            </DialogContent>
            }
            <DialogActions>
                <Spacer />
                <Button
                    onClick={() => { redirect('/') }}
                    variant="contained"
                >
                    {"Voltar à página inicial"}
                </Button>
                <Button
                    onClick={() => { redirect(`/quiz-review/${evaluateData.id}`) }}
                    variant="contained"
                    color="primary"
                >
                    {"Revisar quiz"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default QuizFinishModal