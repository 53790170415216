import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField as MuiTextField, Typography } from "@material-ui/core"
import { Autocomplete, Skeleton } from "@material-ui/lab"
import moment from "moment"
import { useEffect, useState } from "react"
import { Datagrid, Pagination, DateField, ListContextProvider, TextField, useList, useRecordContext, useNotify } from "react-admin"
import dataProvider from "../../../providers/data-provider"
import { storageProvider } from "../../../providers/storage-provider"
import { DateFieldIntl } from "../../date-field-intl"
import { Spacer } from "../../spacer"



const StudentField = (props) => {
    const record = useRecordContext(props);

    return (
        <>
            {record?.memberships?.map((membership, idx) => <div key={`student-${idx + 1}`}>
                <Typography variant="body2">{`${idx + 1}. ${truncateName(membership.student.name)} - ${membership?.class?.name}`}</Typography>
            </div>)}
        </>
    )
}

const isLowerCase = (str) => {
    return str === str?.toLowerCase() && str !== str?.toUpperCase();
}

const truncateName = (name) => {
    if (name) {
        const [firstName, ...lastName] = name.split(' ')
        const ln = lastName[0] ? isLowerCase(lastName[0]) ? lastName[1][0] + '.' : lastName[0][0] + '.' : ''
        return `${firstName} ${ln}`
    }
}

const ManagerField = (props) => {
    const record = useRecordContext(props);

    return (
        <>
            {record?.managers?.map((manager, idx) => <div key={`manager-${idx + 1}`}><Typography variant="body2">{`${idx + 1}. ${truncateName(manager.name)}`}</Typography></div>)}
        </>
    )
}

const RemoveApplyButton = ({ record, resource, disabled, refresh, toggleRefresh, ...props }) => {
    const notify = useNotify()

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const handleClick = async () => {
        setLoading(true)
        await dataProvider.post(resource, `${record.id}/removeStudentApply`, { id: record.id })
            .then(() => {
                notify('Candidatura removida com sucesso!', 'success')
                toggleRefresh(!refresh)
            }).catch(err => {
                notify(err.response.data.message, 'error')
            })
            .finally(() => {
                setLoading(false)
                setOpen(false)
            })
    }

    return (
        <>
            <Button variant="contained" onClick={handleOpen} disabled={record?.process?.id}>{record?.process?.id ? 'Em andamento' : 'Remover'}</Button>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle> Remover candidatura </DialogTitle>
                <DialogContent>
                    <Typography> Deseja remover a candidatura deste agendamento? </Typography>
                    <Typography> {`Data/Hora: ${moment(record.date).format('DD/MM/YYYY hh:mm')}`} </Typography>
                    <Typography> {`Cliente: ${record.client.name}`} </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClose}> Cancelar </Button>
                    <Spacer />
                    <Button variant="contained" color="primary" disabled={loading} onClick={handleClick}> Remover </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const ApplyButton = ({ record, resource, disabled, options, refresh, toggleRefresh, ...props }) => {
    const user = storageProvider.getCurrentUser()
    const notify = useNotify()

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)

    //Filtrar somente turmas que estejam no periodo letivo do agendamento a ser aplicado
    const filteredOptions = options.filter((option) => option.academic_period?.id === record.academic_period.id);
    const handleOpen = () => { setOpen(true); }
    const handleClose = () => setOpen(false)
    const handleChange = (event, value) => setSelected(value)
    const handleSubmit = async () => {
        setLoading(true)
        await dataProvider.post(`schedulings`, `${user.id}/studentApply`, { id: record.id, classId: selected.id })
            .then((res) => {
                notify('Atendimento registrado com sucesso', 'success')
                toggleRefresh(!refresh)
            })
            .catch(err => {
                notify(err.response.data.message, 'error')
            })
            .finally(() => {
                setLoading(false)
                setOpen(false)
            })
    }

    return (
        <>
            <Button variant="contained" onClick={handleOpen}> Atender </Button>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle> Atender agendamento </DialogTitle>
                <DialogContent dividers>
                    <Typography>  Para atender o agendamento selecionado, selecione qual a turma que será representada:</Typography>
                    <Autocomplete
                        id='combobox-select'
                        options={filteredOptions || []}
                        getOptionLabel={c => c.name || 'Selecione...'}
                        getOptionSelected={(o, s) => o.id === s.id}
                        noOptionsText={'Sem resultados'}
                        value={selected}
                        size={'small'}
                        onChange={handleChange}
                        renderInput={(params) => <MuiTextField {...params} label={'Turma'} variant='filled' />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={loading} onClick={handleClose}> Cancelar </Button>
                    <Spacer />
                    <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit}> Atender </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const AvailableSchedulings = ({ user, refresh, toggleRefresh, ...props }) => {

    const [options, setOptions] = useState(null)
    const [availableSchedulings, setAvailableSchedulings] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)

    useEffect(() => {
        const fetchData = async () => {
            await dataProvider.get(`users`, `${user.id}/classes`)
                .then((res) => {
                    setOptions(res.data)
                })
            await dataProvider.get(`schedulings`, `getAvailable`)
                .then((res) => {
                    setAvailableSchedulings(res.data.filter(s => !s.memberships.find(m => m.student.id === user.id)))
                    setLoading(false)
                    setLoaded(true)
                })
        }
        fetchData();
    }, [refresh, user.id])


    const ids = availableSchedulings?.map((row) => row.id)
    const sort = { field: 'date', order: 'ASC' };
    const context = useList({ data: availableSchedulings, ids, loading, loaded, page, perPage, sort, filter: {} })

    return (
        <>
            {
                loading ? <Skeleton variant="rounded" width={'100%'} height={120} /> :
                    <ListContextProvider value={context}>
                        <Datagrid basePath="/schedulings" resource="schedulings">
                            <DateFieldIntl label="Data/Hora" source="date" />
                            <TextField label="Cliente" source="client.name" />
                            <ManagerField label="Gestores" />
                            <TextField label="Tipo" source="type.name" />
                            <TextField label="Período Letivo" source="academic_period.name" />
                            <DateField label="Final candidatura" source="max_apply_date" />
                            <StudentField label="Alunos" />
                            <ApplyButton label="Atender" options={options} refresh={refresh} toggleRefresh={toggleRefresh} />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[10, 25, 50]} />
                    </ListContextProvider>
            }
        </>
    )
}

const UserSchedulings = ({ user, refresh, toggleRefresh, ...props }) => {

    const [userSchedulings, setUserSchedulings] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)

    useEffect(() => {
        const fetchData = async () => {
            await dataProvider.get(`schedulings`, `getUserSchedulings`)
                .then((res) => {
                    setUserSchedulings(res.data)
                    setLoading(false)
                    setLoaded(true)
                })
        }
        fetchData();
    }, [refresh])

    const ids = userSchedulings?.map((row) => row.id)
    const sort = { field: 'date', order: 'ASC' };
    const context = useList({ data: userSchedulings, ids, loading, loaded, page, perPage, sort, filter: {} })

    return (
        <>
            {
                loading ? <Skeleton variant="rounded" width={'100%'} height={120} />
                    : <ListContextProvider value={context}>
                        <Datagrid basePath="/schedulings" resource="schedulings">
                            <DateFieldIntl label="Data/Hora" source="date" />
                            <TextField label="Cliente" source="client.name" />
                            <ManagerField label="Gestores" />
                            <TextField label="Tipo" source="type.name" />
                            <TextField label="Período Letivo" source="academic_period.name" />
                            <DateField label="Final candidatura" source="max_apply_date" />
                            <StudentField label="Alunos" />
                            <RemoveApplyButton label={"Remover canditatura"} refresh={refresh} toggleRefresh={toggleRefresh} />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[10, 25, 50]} />
                    </ListContextProvider>
            }
        </>
    )
}


export const StudentSchedulings = () => {

    const user = storageProvider.getCurrentUser()
    const [refresh, setRefresh] = useState(false)

    const toggleRefresh = () => setRefresh(!refresh)

    return (
        <Grid container spacing={4} style={{ paddingInline: '16px', paddingBlock: '32px' }}>
            <Grid container item direction="column">
                <Typography style={{ paddingLeft: "16px" }}>Meus Agendamentos</Typography>
                <UserSchedulings refresh={refresh} toggleRefresh={toggleRefresh} user={user} />
            </Grid>
            <Grid item>

            </Grid>
            <Grid container item direction="column">
                <Typography style={{ paddingLeft: "16px" }}>Agendamentos disponíveis</Typography>
                <AvailableSchedulings refresh={refresh} toggleRefresh={toggleRefresh} user={user} />
            </Grid>
        </Grid>
    )

}