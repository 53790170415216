import { Grid, Button } from '@material-ui/core';
import React from 'react';
import { Title, useAuthenticated, useRedirect } from 'react-admin';
import QuizList from './quiz-list.component';
import QuizStats from './quiz-stats.component';


const QuizDashboard = (props) => {

    useAuthenticated()
    const redirect = useRedirect()
    return (
        <Grid container>
            <Title title={'Juristta Quiz'} />
            <Button style={{ width: '150px', marginBottom: '32px' }} onClick={() => redirect('/quiz')} variant="contained" color="primary">Novo Quiz</Button>
            <QuizStats />
            <QuizList />
        </Grid >
    )
}

export default QuizDashboard;