import { Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Button, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList, useRedirect } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { TimeIconField } from "../../time-icon-field";

const SchoolStudentDashboardTasks = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [filter, setFilter] = useState({ has_finished: false })
    const [value, setValue] = useState('active');

    useEffect(() => {
        (async () => {
            setLoaded(false)
            setLoading(true)
            dataProvider.get('school-activity-tasks', 'student-school-activity-tasks', { filter: JSON.stringify(filter) })
                .then((res) => {
                    setData(res.data.data)
                    setLoading(false)
                    setLoaded(true)
                })
        })()
    }, [filter])

    const ids = data.map((row) => row.id)
    const sort = { field: 'student_end_at', order: 'ASC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort })

    const handleChange = (event) => {
        let value = event.target.value;
        setValue(value);
        switch (value) {
            case 'active':
            default:
                setFilter({ has_finished: false })
                break;
            case 'finished':
                setFilter({ has_finished: true })
                break;
            case 'all':
                setFilter({})
                break;

        }
    }

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'active'}>Em andamento</MenuItem>
                    <MenuItem value={'finished'}>Finalizadas</MenuItem>
                </Select>
            </FormControl>
            <Divider />
            {
                loading ? <Loading /> :
                    <ListContextProvider value={context}>
                        <Datagrid basePath="/student-school-activity-tasks" resource="school-activity-tasks">
                            <TimeIconField source="student_end_at" label={'Legenda'} />
                            <TextField source="name" label={'Movimentação'} />
                            <TextField source="school_activity.name" label={"Prática"} />
                            <TextField source="school_activity.class.name" label={"Turma"} />
                            <TextField source="school_activity.teams[0].name" label={"Equipe"} />
                            <TextField source="school_activity.teams[0].team_memberships[0].role.name" label={"Sujeito processual"} />
                            <DateField source="start_at" label={'Data de início'} />
                            <DateField source="student_end_at" label={'Prazo final'} />
                            <DashboardButton />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[25, 50, 100]} />
                    </ListContextProvider>
            }
        </>
    )
}

const DashboardButton = ({ record, resource, ...props }) => {
    const redirect = useRedirect()
    const handleClick = () => redirect(`/school-activity-dashboard/${record.school_activity.id}`);
    return <Button label={'DASHBOARD'} size={'small'} onClick={handleClick}><Visibility /></Button>
}

export default SchoolStudentDashboardTasks