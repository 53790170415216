import { Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList, useRedirect } from "react-admin";
import dataProvider from "../../../providers/data-provider";

const SchoolTeacherDashboardActivities = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [filter, setFilter] = useState({ has_finished: false })
    const [value, setValue] = useState('pending');

    useEffect(() => {
        (async () => {
            const resp = await dataProvider.get('school-activities', 'teacher-school-activities', { filter: JSON.stringify(filter) })
            setData(resp.data.data)
            setLoading(false)
            setLoaded(true)
        })()
    }, [filter])

    const ids = data.map((row) => row.id)
    const sort = { field: 'created_at', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter: {} })

    const handleChange = (event) => {
        let value = event.target.value;
        setValue(value);
        switch (value) {
            case 'pending':
            default:
                setFilter({ has_finished: false })
                break;
            case 'finished':
                setFilter({ has_finished: true })
                break;
        }
    }

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'pending'}>Em aberto</MenuItem>
                    <MenuItem value={'finished'}>Finalizadas</MenuItem>
                </Select>
            </FormControl>
            <Divider />
            {
                loading ? <Loading /> :
                    <ListContextProvider value={context}>
                        <Datagrid basePath="/school-activities" resource="school-activity">
                            <TextField source="name" label={'Atividade'} />
                            <TextField source="class.name" label={'Turma'} />
                            <DateField source="start_date" label={'Data de início'} />
                            <DateField source="end_date" label={'Final previsto'} />
                            <DashboardButton />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[25, 50, 100]} />
                    </ListContextProvider>
            }
        </>
    )
}

const DashboardButton = ({ record, resource, ...props }) => {
    const redirect = useRedirect()
    const handleClick = () => redirect(`/school-activity-dashboard/${record.id}`);
    return <Button label={'DASHBOARD'} size={'small'} onClick={handleClick}></Button>
}


export default SchoolTeacherDashboardActivities