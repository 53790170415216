import {
    Box,
    Button,
    Container,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { Spacer } from "../../components/spacer";
import dataProvider from "../../providers/data-provider";
import QuizQuestion from "./quiz-question.component";
import QuizTimer from "./quiz-timer.component";
import QuizFinishModal from "./quiz-finish-modal.component";


const QuizResolutionPage = ({ quiz }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [answered, setAnswered] = useState({});
    const [time_finished, setTimeFinished] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [evaluateData, setEvaluateData] = useState(null)

    const questions = quiz.questions
    const currentQuestion = questions[currentQuestionIndex];

    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        };
    }

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    }

    const handleTimeFinish = (val) => {
        if (!modalOpen && !time_finished) {
            setTimeFinished(val)
            handleSubmitQuiz()
        }
    }

    const handleAnswerFinish = () => {
        setModalOpen(true)
        handleSubmitQuiz()
    }

    const handleSubmitQuiz = async () => {

        setSubmitting(true)
        const parsed = Object.keys(answers).map((key) => {
            return { question_id: key, alternative_id: answers[key] }
        })

        dataProvider.post('quiz', quiz.id, { answers: parsed })
            .then((res) => {
                setEvaluateData(res.data)
            })
            .finally(() => setSubmitting(false))
    }

    return (
        <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
            <Typography variant="h4" gutterBottom>
                {"Resolução do Quiz"}
            </Typography>

            <QuizTimer timeLimit={quiz.time_to_solve ? quiz.time_to_solve * 60 : null} handleTimeFinish={handleTimeFinish} />

            <Stepper style={{ borderRadius: '8px' }} nonLinear activeStep={currentQuestionIndex} alternativeLabel>
                {questions.map((q, index) => (
                    <Step key={index}>
                        <StepButton onClick={() => { setCurrentQuestionIndex(index) }} completed={answered[q.id]}>
                            {questions.length <= 10 && <StepLabel>{`Questão ${index + 1}`}</StepLabel>}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            <QuizQuestion answers={answers} answered={answered} currentQuestion={currentQuestion} setAnswered={setAnswered} setAnswers={setAnswers} disabled={time_finished || modalOpen} />

            <Box display='flex' marginTop="2rem">

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevious}
                    disabled={currentQuestionIndex === 0}
                    style={{ marginRight: '8px' }}
                >
                    {"Anterior"}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={currentQuestionIndex === questions.length - 1}
                >
                    {"Próxima"}
                </Button>
                <Spacer />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAnswerFinish}
                    disabled={time_finished && Object.keys(answered).length < questions.length}
                >
                    {"Finalizar"}
                </Button>
            </Box>
            <QuizFinishModal finishedByAnswers={modalOpen} finishedByTimeout={time_finished} submitting={submitting} evaluateData={evaluateData} />
        </Container>
    );
};

export default QuizResolutionPage;
