import {
    Box,
    Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

const QuizTimer = ({ timeLimit, handleTimeFinish }) => {

    const [timeLeft, setTimeLeft] = useState(timeLimit);

    useEffect(() => {
        if (timeLeft !== null) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        handleTimeFinish(true)
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, handleTimeFinish]);


    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    };

    return (
        <Box textAlign="right" marginBottom="1rem">
            <Typography variant="h6" color="secondary">
                Tempo restante: {formatTime(timeLeft)}
            </Typography>
        </Box>
    )
}

export default QuizTimer;