import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Explore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { BooleanField, Button, Datagrid, DateField, FunctionField, ListContextProvider, Loading, Pagination, TextField, useList } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { DangerousHTMLDiv } from "../../dangerousHTMLDiv";

const StudentDashboardGrades = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [filter, setFilter] = useState({ evaluated: true })
    const [value, setValue] = useState('evaluated');

    //const redirect = useRedirect()

    useEffect(() => {
        (async () => {
            const resp = await dataProvider.getStudentActivityDashboard()
            setData(resp.data[0])
            setLoading(false)
            setLoaded(true)
        })()
    }, [loaded])

    const ids = data.map((row) => row.id)
    const sort = { field: 'deadline', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter })

    const handleChange = (event) => {
        let value = event.target.value;

        setValue(value);

        switch (value) {

            case 'pending':
                setFilter({ evaluated: false })
                break;

            case 'evaluated':
            default:
                setFilter({ evaluated: true })
                break;

            case 'all':
                setFilter({})
                break;
        }
    };

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'all'}> Todos</MenuItem>
                    <MenuItem value={'pending'}>Não corrigidas</MenuItem>
                    <MenuItem value={'evaluated'}>Corrigidas</MenuItem>
                </Select>
            </FormControl>
            <Divider />
            {loading ? <Loading /> :
                <ListContextProvider value={context}>
                    <Datagrid basePath="/activities" resource="activity">
                        <TextField source="process.alias" label={'Protocolo'} />
                        <TextField source="type.name" label={'Movimentação'} />
                        <DateField source="deadline" label={'Data de término'} />
                        <FunctionField source='type.workload_type' label={'Tipo'} render={parseField} />
                        <FunctionField source="type.reference_workload" label={'Referência'} render={parseReferenceWorkload} />
                        <BooleanField source="evaluated" label={'Corrigida'} />
                        <FunctionField source="workload" label={'Avaliação'} render={parseWorkload} />
                        <TeacherObservations />
                    </Datagrid>
                    <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[25, 50, 100]} />
                </ListContextProvider>
            }
        </>
    )
}

const TeacherObservations = ({ record }) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <Button onClick={handleOpen} label={'Observações'}><Explore /></Button>
            <Dialog open={open} onClose={handleClose} style={{}}>
                <DialogTitle>
                    Observações do professor
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <DangerousHTMLDiv html={record.teacher_observations} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button label="Fechar" onClick={handleClose} />
                </DialogActions>
            </Dialog>
        </>
    )
}

const parseField = (record) => {
    const type = record?.type?.workload_type;
    if (type === 'T') return 'Carga horária'
    else if (type === 'G') return 'Nota'
    else return 'Cumprimento de tarefa'
}
const parseReferenceWorkload = (record) => {
    const type = record?.type?.workload_type;
    const value = record?.type?.reference_workload;
    if (type === 'T') return value ? `${value}h` : '-'
    else if (type === 'G') return value ? `${value} pts` : '-'
    else return '-'
}

const parseWorkload = (record) => {
    const type = record?.type?.workload_type;
    const value = record.workload;
    if (type === 'T') return value ? `${value}h` : '-'
    else if (type === 'G') return value ? `${value} pts` : '-'
    else {
        if (value === 'ct') {
            return 'Cumpriu totalmente'
        } else if (value === 'cp') {
            return 'Cumpriu parcialmente'
        } else if (value === 'nc') {
            return 'Não cumpriu'
        } else {
            return '-'
        }
    }
}

export default StudentDashboardGrades