import { Android } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { QuestionCreate } from "./question-create";
import { QuestionEdit } from "./question-edit";
import { QuestionList } from "./question-list";

export const questionResource = {
    name: ApplicationResource.QUESTION,
    icon: Android,
    options: { label: "Banco de questões" },
    link: "/questions",
    tableName: 'question',
    list: QuestionList,
    edit: QuestionEdit,
    create: QuestionCreate,
    products: ['quiz']
}