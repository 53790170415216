import {
    Box,
    Button,
    Container,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Spacer } from "../../components/spacer";
import QuizQuestionReview from "./quiz-question-review.component";
import { Loading } from "react-admin";
import dataProvider from "../../providers/data-provider";

const QuizReviewPage = (props) => {
    const id = props.match.params.id;


    useEffect(() => {
        const fetchData = async () => {
            await dataProvider.get("quiz", `${id}/review`)
                .then((res) => setQuizData(res.data))
        }
        fetchData();
    }, [id])

    const [quizData, setQuizData] = useState(null)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const currentQuestion = quizData ? quizData.questions[currentQuestionIndex] : null;
    const currentAnswer = quizData ? quizData.answers.find(answer => answer.question_id === currentQuestion.id) : null

    const handleNext = () => {
        if (currentQuestionIndex < quizData.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        };
    }

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    }

    return !quizData ? <Loading /> : (
        <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
            <Typography variant="h4" gutterBottom>
                {"Revisão do Quiz"}
            </Typography>

            <Stepper style={{ borderRadius: '8px' }} nonLinear activeStep={currentQuestionIndex} alternativeLabel>
                {quizData.questions.map((q, index) => (
                    <Step key={index}>
                        <StepButton onClick={() => { setCurrentQuestionIndex(index) }}>
                            {quizData.questions.length <= 10 && <StepLabel>{`Questão ${index + 1}`}</StepLabel>}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>

            <QuizQuestionReview currentQuestion={quizData?.questions[currentQuestionIndex]} answer={currentAnswer} />

            <Box display='flex' marginTop="2rem">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevious}
                    disabled={currentQuestionIndex === 0}
                    style={{ marginRight: '8px' }}
                >
                    {"Anterior"}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={currentQuestionIndex === quizData.questions.length - 1}
                >
                    {"Próxima"}
                </Button>
                <Spacer />
            </Box>
        </Container>
    )
}

export default QuizReviewPage;