import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import React from "react";
import { Spacer } from "../../components/spacer";

const QuizQuestionReview = ({ currentQuestion, answer }) => {

    return (
        <Box marginTop="2rem">
            <Grid container>
                <Typography variant="h6" color="textSecondary" align="justify">
                    {`(${currentQuestion?.source?.name?.trim()}) Questão ${currentQuestion?.number?.trim()}`}
                </Typography>
                <Spacer />
                <Typography variant="h6" color="textSecondary" align="justify">
                    {`Direito ${currentQuestion?.theme?.name?.trim()}`}
                </Typography>
            </Grid>
            <Divider style={{ marginBlock: '8px' }} />
            <Typography variant="h6" color="textSecondary" align="justify" gutterBottom>
                {`${currentQuestion.text}`}
            </Typography>
            {currentQuestion.observations && (
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {currentQuestion.observations}
                </Typography>
            )}

            <FormControl component="fieldset">
                <RadioGroup
                    style={{ marginTop: '2rem' }}
                >
                    {currentQuestion.alternatives.map((alt) => (
                        <FormControlLabel
                            style={{ marginBlock: '0.5rem', color: alt.correct ? "green" : answer?.alternative_id === alt.id ? "red" : "inherit" }}
                            key={alt.id}
                            value={alt.id}
                            control={<Radio />}
                            checked={answer?.alternative_id === alt.id}
                            label={alt.text}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default QuizQuestionReview;