import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { BooleanField, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { getUrl } from "../../../utils/urls";
import { TimeIconField } from "../../time-icon-field";
import EvaluateButton from "./evaluate-button";

const SchoolTeacherDashboardAIGrades = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [filter, setFilter] = useState({ evaluated: false })
    const [value, setValue] = useState('pending');
    const [refresh, setRefresh] = useState(new Date())

    useEffect(() => {
        (async () => {
            setLoaded(false)
            setLoading(true)
            const resp = await dataProvider.get('school-activity-task-completions', 'teacher-ai', filter)
            setData(resp.data)
            setLoading(false)
            setLoaded(true)
        })()
    }, [filter, refresh])

    const ids = data.map((row) => row.id)
    const sort = { field: 'id', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter })

    const handleChange = (event) => {
        let value = event.target.value;

        setValue(value);

        switch (value) {
            case 'pending':
            default:
                setFilter({ evaluated: false })
                break;
            case 'evaluated':
                setFilter({ evaluated: true })
                break;
        }
    };

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'pending'}>Não corrigidas</MenuItem>
                    <MenuItem value={'evaluated'}>Corrigidas</MenuItem>
                </Select>
            </FormControl>

            <Divider />
            {
                loading ? <Loading /> : <ListContextProvider value={context}>
                    <Datagrid basePath="/school-activity-task-completions" resource="school-activity-task-completions">
                        <TimeIconField source="task.teacher_end_at" label={'Legenda'} />
                        <TextField source="task.school_activity.name" label={'Atividade prática'} />
                        <TextField source="task.school_activity.class.name" label={'Turma'} />
                        <TextField source="task.name" label={'Movimentação'} />
                        <TextField source="team.name" label={'Processo'} />
                        <TextField source="role.name" label={'Sujeito processual'} />
                        <DateField source="task.student_end_at" label={'Prazo aluno'} />
                        <DateField source="task.teacher_end_at" label={'Prazo final'} />
                        <BooleanField source='evaluated' label={'Corrigido?'} />
                        <TextField source="grade" label={'Nota'} emptyText="-" />
                        <ActivityButton />
                        <EvaluateButton isAiFeedback={true} setRefresh={setRefresh} />
                    </Datagrid>
                    <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[25, 50, 100]} />
                </ListContextProvider>
            }
        </>
    )
}

const ActivityButton = ({ record, resource, ...props }) => {
    return (
        <Button
            href={`${getUrl()}/#/school-activity-dashboard/${record?.task?.school_activity?.id}`}
            color="primary"
            target={'_blank'}
            startIcon={< Edit />}
        >
            Dashboard
        </Button >
    )
}

export default SchoolTeacherDashboardAIGrades