import { Android } from "@material-ui/icons";
import ApplicationResource from "../../utils/resources.enum";
import { QuestionSourceTypeCreate } from "./question-source-type-create";
import { QuestionSourceTypeEdit } from "./question-source-type-edit";
import { QuestionSourceTypeList } from "./question-source-type-list";

export const questionSourceTypeResource = {
    name: ApplicationResource.QUESTION_SOURCE_TYPE,
    icon: Android,
    options: { label: "Tipo de Fonte" },
    link: "/question-source-types",
    tableName: 'question-source-type',
    list: QuestionSourceTypeList,
    edit: QuestionSourceTypeEdit,
    create: QuestionSourceTypeCreate,
    products: ['quiz']
}