import { Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import dataProvider from "../../../providers/data-provider";

const QuizStats = (props) => {
    const [loading, setLoading] = useState(true)
    const [stats, setStats] = useState(null)

    useEffect(() => {
        dataProvider.get('users', 'quiz-stats')
            .then((res) => { setStats(res.data); setLoading(false) })
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Paper>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas parciais</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Últimos 10 realizados</Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : stats.total_questions > 0 ? `${(100 * (stats.correct_questions / stats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Paper>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas totais</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Todas as avaliações</Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : stats.total_questions > 0 ? `${(100 * (stats.correct_questions / stats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Paper>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas por área</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Área do direito: _______ </Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : stats.total_questions > 0 ? `${(100 * (stats.correct_questions / stats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid >
    )
}

export default QuizStats;