import { Divider, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { BooleanField, Button, Datagrid, DateField, ListContextProvider, Loading, Pagination, TextField, useList, useRedirect } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { TimeIconField } from "../../time-icon-field";

const StudentDashboardActivities = (props) => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(25)
    const [filter, setFilter] = useState({ finished: false })
    const [value, setValue] = useState('pending');

    useEffect(() => {
        (async () => {
            const resp = await dataProvider.getStudentActivityDashboard()
            setData(resp.data[0])
            setLoading(false)
            setLoaded(true)
        })()
    }, [filter])

    const ids = data.map((row) => row.id)
    const sort = { field: 'deadline', order: 'DESC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter })

    const handleChange = (event) => {
        let value = event.target.value;
        setValue(value);
        switch (value) {
            case 'pending':
            default:
                setFilter({ finished: false })
                break;
            case 'finished':
                setFilter({ finished: true })
                break;
            case 'all':
                setFilter({})
                break;

        }
    }

    return (
        <>
            <FormControl style={{ marginBlock: '10px', marginInline: '10px', minWidth: '150px' }}>
                <InputLabel id={'filter-select-label'}>Filtro</InputLabel>
                <Select labelId={'filter-select-label'} id={'filter-select'} value={value} disabled={loading} onChange={handleChange} variant="filled">
                    <MenuItem value={'all'}> Todos </MenuItem>
                    <MenuItem value={'pending'}>Em aberto</MenuItem>
                    <MenuItem value={'finished'}>Finalizadas</MenuItem>
                </Select>
            </FormControl>
            <Divider />
            {
                loading ? <Loading /> :
                    <ListContextProvider value={context}>
                        <Datagrid basePath="/activities" resource="activity">
                            <TimeIconField source="deadline" label={'Legenda'} />
                            <TextField source="process.alias" label={'Protocolo'} />
                            <TextField source="process.client.name" label={"Cliente"} />
                            <TextField source="type.name" label={'Movimentação'} />
                            <DateField source="start_at" label={'Data de início'} />
                            <DateField source="deadline" label={'Prazo final'} />
                            <BooleanField source="finished" label={'Finalizado?'} />
                            <DashboardButton />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[25, 50, 100]} />
                    </ListContextProvider>
            }
        </>
    )
}

const DashboardButton = ({ record, resource, ...props }) => {
    const redirect = useRedirect()
    const handleClick = () => redirect(`/process-dashboard/${record.process.id}`);
    return <Button label={'DASHBOARD'} size={'small'} onClick={handleClick}><Visibility /></Button>
}

export default StudentDashboardActivities