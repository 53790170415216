import { Chip, Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Datagrid, ListContextProvider, Loading, NumberField, Pagination, useList, useRecordContext } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { DateFieldIntl } from "../../date-field-intl";

const TimeElapsedField = ({ ...props }) => {

    const record = useRecordContext(props)
    const start = moment(record.started_at)
    const end = moment(record.finished_at)

    return <Typography> {isNaN(end) ? "-" : moment.duration(end.diff(start)).asMinutes().toFixed(0)} </Typography>
}

const ThemesField = ({ ...props }) => {
    const record = useRecordContext(props)
    return record.themes.map(item => (
        <Chip size='small' style={{ marginRight: '4px' }} key={item.name} label={item.name} />
    ))
}

const QuizList = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)

    useEffect(() => {
        (async () => {
            const resp = await dataProvider.get('quiz', 'user-quizzes')
            setData(resp.data)
            setLoading(false)
            setLoaded(true)
        })()
    }, [])

    const ids = data.map((row) => row.id)
    const sort = { field: 'start_date', order: 'ASC' };
    const context = useList({ data, ids, loading, loaded, page, perPage, sort, filter: {} })


    return (
        <Paper style={{ width: '100%', marginTop: '16px' }}>
            <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                <Typography variant="h5" style={{ marginBottom: '16px' }}>Avaliações recentes</Typography>
                {
                    loading ? <Loading /> : <ListContextProvider value={context}>
                        <Datagrid basePath="/quiz" resource="quiz">
                            <ThemesField label="Áreas" />
                            <DateFieldIntl source="started_at" label="Iniciado em" />
                            <DateFieldIntl source="finished_at" label="Terminado em" />
                            <NumberField source="time_to_solve" label="Tempo estipulado" />
                            <TimeElapsedField label="Tempo gasto" />
                            <NumberField source="question_amount" label="n° de questões" />
                            <NumberField source="grade" label="Nota" />
                        </Datagrid>
                        <Pagination page={page} setPage={setPage} setPerPage={setPerPage} perPage={perPage} rowsPerPageOptions={[10, 25, 50]} />
                    </ListContextProvider>
                }
            </Grid>
        </Paper>
    )
}

export default QuizList;